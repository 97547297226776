import { Timestamp } from "firebase/firestore";

export default function convertTimestampsToSeconds(obj) {
  if(!obj) {
    return obj;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value instanceof Timestamp) {
        // Convert Firestore Timestamp to seconds
        obj[key] = obj[key]?.toDate()?.toISOString()
      } else if (typeof value === 'object') {
        // If the value is an object, recursively call the function
        obj[key] = convertTimestampsToSeconds(value);
      }
    }
  }
  return obj;
}
