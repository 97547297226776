import { Timestamp } from "firebase/firestore";

export default function convertToTimestamps(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (typeof value === 'string' && value?.endsWith("Z") && !isNaN(Date.parse(value))) {

                // Check if it's a valid date string
                obj[key] = Timestamp.fromDate(new Date(value));
            } else if (typeof value === 'object') {
                // If the value is an object, recursively call the function
                obj[key] = convertToTimestamps(value);
            }
        }
    }
    return obj;
}