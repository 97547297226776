import { FieldPath, addDoc, collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "$lib/firebase";
import LocalStorage from "./utils/LocalStorage";
import convertTimestampsToSeconds from "./utils/convertTimestampsToSeconds";
import { captureException } from "@sentry/sveltekit";
import convertToTimestamps from "$lib/utils/convertToTimestamps"

const UsersCache = new LocalStorage("sculptx-users");


export async function createUser(id, payload) {
  try {
    payload = {
      ...payload,
      created_at: new Date().toISOString()
    }

    await setDoc(doc(db, "users", id), convertToTimestamps(payload));

    payload.id = id;

    UsersCache.set(payload.id, payload);

    return payload;


    /*

    const userRef = await addDoc(docRef, {
      ...payload,
      created_at: serverTimestamp()
    });

    payload.id = userRef.id;

    UsersCache.set(payload.id, payload);

    return payload;
    */
  } catch (e) {
    captureException(e);
    throw e;
  }
}

export async function fetchUser(id) {
  if(!id) {
    return;
  }

  try {
    let userProfile = UsersCache.get(id);

    if (userProfile) {
      return userProfile;
    }

    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      userProfile = {
        id,
        ...convertTimestampsToSeconds(docSnap.data())
      };

      UsersCache.set(id, userProfile)

      return userProfile;
    } else {
      // unable to find it.
    }
  } catch (e) {
    captureException(e);
    throw e;
  }
}

export async function deleteUserProfile(id) {
  if(!id) {
    return;
  }

  try {
    await deleteDoc(doc(db, "users", id));

    UsersCache.unset(id);
  } catch(e) {
    captureException(e);
    throw e;
  }
}

export async function fetchUsers({
  userIds
}) {
  try {
    let users = {};
    let fetchUserIds = [];

    if (!userIds?.length) {
      return users;
    }

    userIds.forEach(id => {
      let userProfile = UsersCache.get(id);

      if (userProfile) {
        users[id] = userProfile;
      } else {
        fetchUserIds.push(id);
      }
    });


    if (fetchUserIds?.length) {
      const q = query(collection(db, "users"), where("__name__", "in", fetchUserIds));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        let userProfile = {
          id: doc.id,
          ...doc.data()
        }

        users[userProfile.id] = userProfile;

        UsersCache.set(doc.id, userProfile);
      });
    }

    return users;
  } catch (e) {
    console.log(e);
  }
}

export async function removeUserFromTrainer({ profileId }) {
  try {
    await updateUser({
      id: profileId,
      payload: {
        trainerId: null
      }
    });

    UsersCache.unset(profileId);
  } catch (error) {
    captureException(error);

    throw error;
  }
}

export async function updateUser({ id, payload }) {
  try {
    if (id) {
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        delete payload.id;

        await updateDoc(docRef, convertToTimestamps(payload));

        // Update the user profile in the cache
        const updatedUserProfile = {
          id: id,
          ...docSnap.data(),
          ...payload
        };

        UsersCache.set(id, updatedUserProfile);

        return updatedUserProfile;
      }
    }
  } catch (e) {
    captureException(e);

    throw e;
  }
}


let _fetchedClients = false;

export async function fetchClients({
  trainerId
}) {
  try {
    let users = {};

    if (_fetchedClients) {
      return UsersCache.toJSON();
    }

    if (trainerId) {
      const q = query(collection(db, "users"), where("trainerId", "==", trainerId));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        let userProfile = {
          id: doc.id,
          ...doc.data()
        }

        users[userProfile.id] = convertTimestampsToSeconds(userProfile);

        UsersCache.set(doc.id, userProfile);
      });

      _fetchedClients = true;
    }

    return users;



    /*
      const q = query(collection(db, "users"), where("__name__", "in", fetchUserIds));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        let userProfile = {
          id: doc.id,
          ...doc.data()
        }

        users[userProfile.id] = userProfile;

        UsersCache.set(doc.id, userProfile);
      });
    */
  } catch (e) {
    console.log(e)
  }







  /*
  try {
    let users = {};
    let fetchUserIds = [];

    if (!userIds?.length) {
      return users;
    }

    userIds.forEach(id => {
      let userProfile = UsersCache.get(id);

      if (userProfile) {
        users[id] = userProfile;
      } else {
        fetchUserIds.push(id);
      }
    });

    console.log(fetchUserIds)

    if (fetchUserIds?.length) {
      const q = query(collection(db, "users"), where("__name__", "in", fetchUserIds));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        let userProfile = {
          id: doc.id,
          ...doc.data()
        }

        users[userProfile.id] = userProfile;

        UsersCache.set(doc.id, userProfile);
      });
    }

    return users;
  } catch (e) {
    console.log(e);
  }
  */

}

/*
{
  name: '16 Week Strength Volume Cycle',
  weeks: 16,
  working_max: {
    275: 180, // squat
    21: 150, // bench
    71: 235 // deadlift
  },
  started_at: {
    seconds: new Date().getSeconds()
  }
}
*/